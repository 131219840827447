import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Close } from "../../assests/icons/close.svg";
import { ReactComponent as Logo } from "../../assests/icons/logoDark.svg";
import "./SideNavigation.css";

type SideNavigationProps = {
  closeSideNav: () => void;
  page: string;
};

const SideNavigation = ({ page, closeSideNav }: SideNavigationProps) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const navigationLinks = [
    {
      page: "PERSONAL",
      to: "/personal",
      text: "Personal",
    },
    {
      page: "BUSINESS",
      to: "/business",
      text: "Business",
    },
    {
      page: "CARD",
      to: "/card",
      text: "Card",
    },
    {
      page: "HELP",
      to: "/help",
      text: "Help",
    },
  ];

  const onHideMenu = (route?: string) => {
    setMenuOpen(false);
    setTimeout(() => {
      if (route) navigate(route);
      closeSideNav();
    }, 300);
  };

  useEffect(() => {
    setTimeout(() => {
      setMenuOpen(true);
    }, 30);
  }, []);

  return (
    <div
      onClick={() => {
        onHideMenu();
      }}
      className={`bg-lightBlack z-[9] fixed top-0 left-0 right-0 bottom-0 transition-all ease-in-out delay-150`}
    >
      <div className="w-[280px] nav bg-white fixed top-0 bottom-0  z-[10] text-xl ">
        <div className="flex justify-between items-center px-4 py-3 bg-white">
          <Logo className="w-28 md:w-32" onClick={() => onHideMenu("/")} />
          <Close className="h-5 w-5 fill-black " onClick={() => onHideMenu()} />
        </div>

        <div className="flex flex-col gap-y-9">
          <div className="flex flex-col gap-y-4 p-6">
            {navigationLinks.map((item) => (
              <div className="relative text-black" key={item.to}>
                <Link
                  className={`${page === item.page ? "text-lightGreen" : ""}`}
                  to={item.to}
                >
                  {item.text}
                </Link>
                {page === item.page && (
                  <div className="absolute top-[40%] right-0 h-[6px] w-[6px] rounded-full bg-yellow-400" />
                )}
              </div>
            ))}
          </div>

          <div className="flex flex-col justify-center items-start p-6 gap-y-4">
            <a
              href={
                /Android/i.test(navigator.userAgent)
                  ? "https://play.google.com/store/apps/details?id=com.vtmoney.app"
                  : "https://apps.apple.com/gh/app/vtmoney/id6449646369"
              }
              target="_blank"
              className="text-lightGreen"
              rel="noopener noreferrer"
            >
              Login
            </a>
            <a
              href={
                /Android/i.test(navigator.userAgent)
                  ? "https://play.google.com/store/apps/details?id=com.vtmoney.app"
                  : "https://apps.apple.com/gh/app/vtmoney/id6449646369"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                className="rounded-md border-0 bg-lightGreen font-black text-white h-12 px-6 hover:scale-105"
              >
                Create an account
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNavigation;
