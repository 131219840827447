/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { TransferPropType } from "./types";

const initialStateValue: TransferPropType = {
  amount: 0,
  account_number: "",
  bank_name: "",
  name: "",
  fee: 0,
  alias: "",
  note: "",
  currency: "ngn",
  address: "",
  city: "",
  country: "",
  inside_us: 0,
  swift_code: "",
  zipcode: "",
  bank_code: "",
  pin: "",
  issuer: "",
  delivery_type: "",
  routing_number: "",
  save_beneficiary: true,
  proceed: false,
};

export const transferSlice = createSlice({
  name: "transfer",
  initialState: { value: initialStateValue },
  reducers: {
    setTransfer: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
    resetTransfer: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setTransfer, resetTransfer } = transferSlice.actions;

export default transferSlice.reducer;
