// import { useState } from "react";
import { useParams } from "react-router-dom";
// import AmountPage from "./AmountPage/AmountPage";
import ENaira from "./Enaira/ENaira";
import NotFound from "../../components/ui/page/NotFound";
import Remittance from "./Remittance/Remittance";
import MomoPage from "./Momo";

const SendFunds = () => {
  const { page } = useParams();
  const displayPage = () => {
    switch (page) {
      case "remittance":
        return <Remittance />;
      case "enaira":
        return <ENaira />;
      case "momo":
        return <MomoPage />;
      default:
        return <NotFound />;
    }
  };
  return <div>{displayPage()}</div>;
};

export default SendFunds;
