import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler } from "react-hook-form";

import logo from "../../../assests/icons/logoDark.svg";
import back from "../../../assests/icons/back.svg";

import Transfer from "../../../utils/apiServices/transfer";
import { useDispatch, useSelector } from "react-redux";
import { setTransfer } from "../reducer";
import notify from "../../../utils/notify";
import ResolveRemittance from "./ResolveRemittance";
import NotFound from "../../../components/ui/page/NotFound";
import GetRemittanceFee from "./GetRemittanceFee";
import { IRootState } from "../../..";
import SendPage from "../Enaira/SendPage";
import Pin from "./Pin";
import SendUSDPage from "../USD/SendUSDPage";

const Remittance = () => {
  const user = useSelector((state: IRootState) => state.transfer.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [currency, setCurrency] = useState(user.currency || "NGN");
  const [location, setLoacation] = useState<number>(user.inside_us || 0);

  const [accountName, setAccountName] = useState(null);
  const [accountNumber, setAccountNumber] = useState("");

  const onVerify: SubmitHandler<FieldValues> = (formData) => {
    setLoading(true);
    Transfer.resolveRemittance({
      account_number: accountNumber || user.account_number,
      currency: currency,
      bank_code: formData.bank.value || user.bank_code,
    })
      .then((res) => {
        if (res.status) {
          const { data } = res;
          // console.log(data);

          // dispatch(resetTransfer());
          dispatch(
            setTransfer({
              name: data.account_name,
              bank_name: formData.bank.label || user.bank_name,
              account_number: data.account_number,
              currency: currency,
              bank_code: formData.bank.value,
            })
          );
          setAccountName(data.account_name);
          setLoading(false);
          dispatch(setTransfer({ proceed: true }));
        } else {
          setLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "check your internet connection");
      });
  };

  // GET FEE
  const onGetFee: SubmitHandler<FieldValues> = (formData) => {
    setLoading(true);
    Transfer.getRemittanceFee({
      currency: user.currency,
      amount: formData.amount,
      bank_name: user.bank_name,
    })
      .then((res) => {
        if (res.status) {
          const { data } = res;
          dispatch(
            setTransfer({
              amount: parseInt(formData.amount),
              fee: data.fee,
              note: formData.description,
            })
          );
          setLoading(false);
          setCurrentPage("send");
        } else {
          setLoading(false);
          notify("error", res.message);
        }
      })
      .catch(() => {
        setLoading(false);
        notify("error", "check your internet connection");
      });
  };

  // Send USD
  const onSendUSD: SubmitHandler<FieldValues> = (formData) => {
    dispatch(
      setTransfer({
        amount: formData.amount,
        name: formData.account_name,
        account_number: formData.account_number,
        address: formData.address,
        city: formData.city,
        bank_name: formData.bankname,
        country: "USA",
        delivery_type:
          user.inside_us === 1 ? `${formData.delivery_type.value}` : "",
        inside_us: user.inside_us,
        routing_number: formData.routing_number,
        note: formData.note,
        swift_code: formData.swift_code,
        zipcode: formData.zipcode,
      })
    );
    setType("usd");
    setCurrentPage("sendusd");
  };

  const [currentPage, setCurrentPage] = useState<
    "resolve" | "getfee" | "send" | "sendusd" | "pin"
  >("resolve");

  const setResolve = () => {
    setAccountName(null);
    setCurrentPage("resolve");
    dispatch(setTransfer({ proceed: true }));
  };

  const onGetBackFee = () => {
    setCurrentPage("getfee");
  };
  const displayPage = () => {
    switch (currentPage) {
      case "resolve":
        return (
          <ResolveRemittance
            setAccountName={setAccountName}
            setAccountNumber={setAccountNumber}
            accountNumber={accountNumber}
            setGetFee={() => setCurrentPage("getfee")}
            proceed={user.proceed}
            loading={loading}
            onVerify={onVerify}
            onSendUSD={onSendUSD}
            accountName={accountName || ""}
            currency={currency}
            setCurrency={setCurrency}
            location={location}
            setLocation={setLoacation}
          />
        );
      case "getfee":
        return <GetRemittanceFee loading={loading} onVerify={onGetFee} />;
      case "send":
        return (
          <SendPage onSend={() => setCurrentPage("pin")} loading={loading} />
        );
      case "sendusd":
        return (
          <SendUSDPage onSend={() => setCurrentPage("pin")} loading={loading} />
        );
      case "pin":
        return <Pin currency={currency} type={type} />;
      default:
        return <NotFound />;
    }
  };

  return (
    <div className="mb-10">
      <div className="flex sticky top-0 z-[5] bg-white justify-between items-center text-black container mx-auto py-5 mb-10 px-4 ">
        <Link to="/dashboard">
          <img src={logo} alt="" className="w-28 md:w-32" />
        </Link>
        <div
          className="flex justify-start gap-2 items-center text-lightGreen cursor-pointer text-sm md:text-base"
          onClick={() =>
            currentPage === "getfee"
              ? setResolve()
              : currentPage === "send"
              ? onGetBackFee()
              : currentPage === "sendusd"
              ? setResolve()
              : currentPage === "pin"
              ? type === "usd"
                ? setCurrentPage("sendusd")
                : setCurrentPage("send")
              : navigate(-1)
          }
        >
          <span>
            <img src={back} alt="" />
          </span>{" "}
          Go back
        </div>
      </div>
      <div className="flex container mx-auto items-center justify-around bg-red">
        <div className=" flex flex-col text-black justify-center px-4 md:px-20 w-full md:w-[80%] lg:w-[55%] xl:[45%]">
          {displayPage()}
        </div>
      </div>
    </div>
  );
};

export default Remittance;
