import { FieldValues, SubmitHandler } from "react-hook-form";
import Select from "react-select";
import { ReactComponent as PlayDown } from "../../../assests/icons/playDown.svg";

import NGN from "./NGN";
import USD from "./USD";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../..";
import { resetTransfer } from "../reducer";

type ResolveRemittancePropType = {
  onVerify: SubmitHandler<FieldValues>;
  accountName: string;
  accountNumber: any;
  setAccountNumber: (data: any) => void;
  loading: boolean;
  setAccountName: (e: any) => void;
  setGetFee: () => void;
  proceed: boolean;
  currency: string;
  setCurrency: (e: string) => void;
  location: number;
  setLocation: (e: number) => void;
  onSendUSD: SubmitHandler<FieldValues>;
};

const ResolveRemittance = ({
  loading,
  accountName,
  onVerify,
  currency,
  proceed,
  setGetFee,
  setCurrency,
  location,
  setLocation,
  onSendUSD,
  accountNumber,
  setAccountNumber,
  setAccountName,
}: ResolveRemittancePropType) => {
  const dispatch = useDispatch();
  const transfer = useSelector((state: IRootState) => state.transfer.value);

  return (
    <div>
      <div className="">
        <label
          className="text-sm md:text-[17px] font-medium"
          htmlFor="currency"
        >
          Select Currency
        </label>
        <div className="FormSelectMain">
          <Select
            defaultInputValue={transfer.currency.toUpperCase()}
            onChange={(e: any) => {
              dispatch(resetTransfer());
              setCurrency(e.value);
            }}
            options={[
              { value: "ngn", label: "NGN" },
              { value: "usd", label: "USD" },
            ]}
          />
        </div>
        {currency.toLowerCase() === "ngn" ? (
          <NGN
            accountNumber={accountNumber}
            accountName={accountName}
            setAccountNumber={setAccountNumber}
            loading={loading}
            onVerify={onVerify}
            proceed={proceed}
            setGetFee={setGetFee}
          />
        ) : currency.toLowerCase() === "usd" ? (
          <USD
            loading={loading}
            location={location}
            onSendUSD={onSendUSD}
            setLocation={setLocation}
          />
        ) : (
          <div>HERE</div>
        )}
      </div>
    </div>
  );
};

export default ResolveRemittance;
