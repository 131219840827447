import baseURL from "../baseUrl";
import getAccessToken from "../../accessToken";
import { GetSingleAccountParameter } from "./types";

class MyAccount {
  // Get Account
  async getAllAccounts() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/accounts`, {
      method: "get",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.json();
  }

  async getSingleAccount(data: GetSingleAccountParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/accounts/${data.uuid}`, {
      method: "get",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      return response.json();
    }

    return { message: `${response.statusText}. unable to get document types` };
  }

  async getTransactions(data: { page?: string; search?: string }) {
    const accessToken = getAccessToken();

    const page = data.page || "1";

    let url;

    if (data.search) {
      url = `${baseURL}/accounts/transactions?search=${data.search}`;
    } else {
      url = `${baseURL}/accounts/transactions?page=${page}`;
    }
    const response = await fetch(url, {
      method: "get",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.json();
  }
}

const instance = new MyAccount();

export default instance;
