import {
  GetFeeParameters,
  GetMomoFeeParameter,
  GetRemittanceFeeParameter,
  ResolveENaiaParameter,
  ResolveRemittanceParameter,
  SendMomoParameter,
  SendMoneyParameter,
  SendRemittanceParameter,
  SendUsdRemittanceParameters,
} from "./types";

import getAccessToken from "../../accessToken";
import baseURL from "../baseUrl";

class Transfer {
  // Resolve ENaira
  async resolve(data: ResolveENaiaParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/transfer/enaira/resolve`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  // Get fee
  async getFee(data: GetFeeParameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/transfer/enaira/fee`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  // Send Money
  async sendMoney(data: SendMoneyParameter) {
    const accessToken = getAccessToken();

    const response = await fetch(`${baseURL}/transfer/enaira/send`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  // Resolve Remitance
  async resolveRemittance(data: ResolveRemittanceParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/transfer/remittance/resolve`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  // remittance fee
  async getRemittanceFee(data: GetRemittanceFeeParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/transfer/remittance/fee`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  // send remittance
  async sendRemittance(data: SendRemittanceParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/transfer/remittance/send`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  // send remittance
  async sendUSD(data: SendUsdRemittanceParameters) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/transfer/usd/send`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  // MOMO
  async getIssuer(data: string) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/transfer/momo/issuers/${data}`, {
      method: "get",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.json();
  }

  // MOMO fee
  async getMomoFee(data: GetMomoFeeParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/transfer/momo/fee`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async sendMomo(data: SendMomoParameter) {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/transfer/momo/send`, {
      method: "post",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async getBanks() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/transfer/banks`, {
      method: "get",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.json();
  }
  async getRate() {
    const accessToken = getAccessToken();
    const response = await fetch(`${baseURL}/transfer/rates`, {
      method: "get",
      headers: {
        "X-VTMoney-Client": "web-user",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.json();
  }
}

const instance = new Transfer();
export default instance;
