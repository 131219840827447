import { createSlice } from "@reduxjs/toolkit";
import { UserType } from "./types";

const initialStateValue: UserType = {
  id: 0,
  business_name: "",
  business_name_suffix: "",
  uuid: "",
  firstname: "",
  card: null,
  middlename: "",
  lastname: "",
  name: "",
  email: "",
  phone: "",
  country_code: "",
  type: "",
  dob: "",
  country: "",
  state: "",
  city: "",
  address: "",
  postal_code: "",
  profile_path: "",
  profile_photo_url: "",
  has_kyc: false,
  loggedIn: false,
  has_card: false,
  has_pin: false,
  banks: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState: { value: initialStateValue },
  reducers: {
    login: (state, action) => {
      // Set Token
      sessionStorage.setItem("accessToken", action.payload.access_token);
      if (action.payload.remember_me) {
        localStorage.setItem("accessToken", action.payload.access_token);
      }
      state.value = action.payload;
    },
    setUser: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },

    logout: (state) => {
      localStorage.clear();
      sessionStorage.clear();
      state.value = initialStateValue;
    },
  },
});

export const { login, setUser, logout } = userSlice.actions;
export default userSlice.reducer;
