import Footer from "../../layout/Footer/Footer";
import Navigation from "../../layout/Navigation/Navigation";
import { ReactComponent as Phone } from "../../assests/icons/phone.svg";
import { ReactComponent as Mail } from "../../assests/icons/mail.svg";
import { ReactComponent as Location } from "../../assests/icons/location.svg";
import { ReactComponent as Message } from "../../assests/icons/message.svg";
import Vec from "../../assests/images/contact-vector.png";
import contactHero from "../../assests/images/contact-hero.png";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div className="">
      <Navigation page="Contact Us" />
      <div className="bg-contactBg">
        <div className="container mx-auto flex flex-col justify-start items-baseline text-white pt-20 md:pt-36 pb-20 px-4 md:px-[110px]">
          <div className="font-black text-[18.37px] md:text-[35px] mb-6 md:mb-10">
            Hello👋
          </div>
          <div className="font-black text-[18.37px] md:text-[35px] mb-3 md:mb-4">
            How can we help you today?
          </div>
          <div className="font-medium mb-10 leading-[24px] md:leading-[38px]">
            Let's get you going with the dependable support you needs. Consult
            our
            <br className="hidden md:block" />
            <span className="underline decoration-dotted">
              <Link to="/faqs"> FAQs</Link>
            </span>{" "}
            or get in touch with our support staff.
          </div>
          <div className="relative">
            <img className="self-center mb-10" src={contactHero} alt="" />
            <img
              className="absolute -top-12 md:-top-20 right-0 w-[120px] md:w-fit"
              src={Vec}
              alt=""
            />
            <div className="absolute -top-3 right-7 md:right-12 flex flex-col justify-center items-center">
              <Message />
              <div className="text-base md:text-[22.5px] font-medium text-black">
                Contact Us
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row bg-trans rounded-2xl px-10 md:px-20 py-10 gap-7 justify-center items-center md:justify-between  self-center w-full">
            <div className="flex flex-col gap-4 items-center">
              <Phone className="mb-4" />
              <div className="font-medium text-[14.4px]">+01 234 567 89</div>
              <div className="font-medium text-[14.4px]">+01 234 567 89</div>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <Mail className="mb-4" />
              <a
                href="mailto:support@vtmoney.com"
                className="font-medium text-[14.4px]"
              >
                support@vtmoney.com
              </a>
            </div>
            <div className="flex flex-col gap-4 items-center">
              <Location className="mb-4" />
              <div className="font-medium text-[14.4px] text-center">
                2118 Thornridge Cir. Syracuse, Connecticut 35624
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-lightGreen">
        <div className="container mx-auto text-white px-4 md:px-[110px] py-10 md:py-28">
          <div className="font-black text-[29.3px] md:text-[60px]">
            Get In Tounch
          </div>
          <div className="md:text-[22.5px] text-[16px] leading-[20px] pt-4 md:leading-[38px] mb-10 md:mb-20">
            Please contact us at any time.
            <br />
            Any issue would be happily resolved by us.
          </div>
          {/* FORM CHANGE LATER TO REACT FORM */}
          <div className="flex flex-col justify-center items-start gap-8">
            <div className=" flex flex-col md:flex-row gap-3 w-full">
              <div className="flex flex-col space-y-2 w-full">
                <div className="font-medium text-lg">Your Name</div>
                <input
                  className="border-0 outline-none bg-transWhite text-black rounded p-2"
                  type="text"
                />
              </div>
              <div className="flex flex-col space-y-2 w-full">
                <div className="font-medium text-lg">Reason For Contact</div>
                <input
                  className="border-0 outline-none bg-transWhite text-black rounded p-2"
                  type="text"
                />
              </div>
            </div>
            <div className=" flex flex-col md:flex-row gap-3 w-full">
              <div className="flex flex-col gap-3 w-full">
                <div className="font-medium text-lg">Phone Number</div>
                <input
                  className="border-0 outline-none bg-transWhite text-black rounded p-2"
                  type="tel"
                />
                <div className="font-medium text-lg pt-4">Email Address</div>
                <input
                  className="border-0 outline-none bg-transWhite text-black rounded p-2"
                  type="email"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <div className="font-medium text-lg">Reason For Contact</div>
                <textarea
                  className="border-0 outline-none bg-transWhite text-black rounded p-2 h-full"
                  rows={4}
                  // type="text"
                ></textarea>
              </div>
            </div>
            <button className="mt-10 md:mt-20 rounded-md border-0 bg-white font-black text-lightGreen h-12 px-16 w-full md:w-fit">
              Submit
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
